/* You can add global styles to this file, and also import other style files */
@import '~firebaseui/dist/firebaseui.css';
@import "primeicons/primeicons.css";
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;500;600;700&family=Montserrat:wght@400;500;600;700&display=swap');

:root {
  --color-light-pink: #F443360D;
  --color-light-red: #E91E630D;
  --color-light-purple: #9C27B00D;
  --color-light-grey: #3F51B50D;
  --color-light-blue: #2196F30D;
  --color-light-green: #00BCD40D;
  --color-light-bluegreen: #0096880D;
  --color-light-tiel: #4CAF500D;
  --color-light-yellow: #FFC1070D;
  --color-light-salmon: #FF98000D;
  --color-light-orange: #FF57220D;
  --color-light-bluegrey: #607D8B0D;
}

html {
  font-size: 14px;
}

body {
  font-family: var(--font-family);
  font-weight: normal;
  background: white;
  color: var(--text-color);
  padding: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.card {
  background: var(--surface-card);
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.p-button {
  background: #2196f3;
  border-radius: 8px !important;
}

.p-button-info {
  background: #cf44ff !important;
}

.p-button-secondart {
  background: lightgrey !important;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
}

.row-justify {
  display: flex;
  justify-content: end;
  flex-direction: row;
  align-items: center;
}

.col-25 {
  flex-basis: calc(25%);
  max-width: calc(25%);
  margin: 0.5rem;
}

.col-4 {
  flex-basis: calc(40%);
  max-width: calc(40%);
}

.col-5 {
  flex-basis: calc(60%);
  max-width: calc(60%);
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 1rem;
}

.footer {
  background-color: #333; /* Background color for the footer */
  color: #fff; /* Text color */
  padding: 20px 0; /* Add padding to the top and bottom of the footer */
  text-align: center; /* Center-align the text */
  position: absolute;
  bottom: 0;
  width: 100%;
}

/* Style the anchor links within the footer */
.footer-links {
  display: flex; /* Use flexbox to center-align the links */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.footer-links a {
  text-decoration: none; /* Remove underlines from links */
  margin: 0 10px; /* Add spacing between links */
  display: flex;
  flex-wrap: wrap;
}
